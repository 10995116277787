@import "~/node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "./sass/index.scss";

@font-face {
	font-family: "Inter";
	src: url("./fonts/Inter-Regular.ttf");
	font-weight: 400;
}
@font-face {
	font-family: "Inter";
	src: url("./fonts/Inter-SemiBold.ttf");
	font-weight: 600;
}
@font-face {
	font-family: "Inter";
	src: url("./fonts/Inter-Bold.ttf");
	font-weight: 700;
}
@font-face {
	font-family: "Inter";
	src: url("./fonts/Inter-Black.ttf");
	font-weight: 900;
}

$text: rgb(16, 16, 16);
$green: #04724d;
$silvergrey: #ededed;
$greytext: #b9b9b9;
$gray: #616161;
$darkbg: #1a1a1a;
$semidarkbg: #2e2e2e;
$lightbg: #f7f7f7;
$formbg: #f5f5f5;

$sm: 576px;
$md: 768px;
$lg: 992px;

html,
body {
	font-family: "Inter";
	color: $text;
	height: 100vh;
	overflow: hidden;
}

strong {
	font-weight: 700;
}

.container {
	max-width: 922px;
	padding-left: 16px;
	padding-right: 16px;
}

.green {
	color: $green;
}

.btn {
	background-color: $green;
	color: #fff;
	border-radius: 50px;
	font-weight: 700;
	padding: 10px 24px;
	padding-top: 9px;
	padding-bottom: 12px;
	text-decoration: none;
	display: flex;
	line-height: 19px;
	align-items: center;
	border: 0;
	transition: all 0.3s;
	&--black {
		background-color: #000;
	}
	&:hover {
		background-color: #000;
	}
}

.header {
	&--open {
	}
	&__container {
		max-width: 1352px;
		padding-left: 16px;
		padding-right: 16px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 23px;
		padding-bottom: 23px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 20;
		background-color: #fff;
		&--open {
			border-bottom: 1px solid rgba($greytext, 0.6);
		}
		@media (max-width: $md) {
			padding-top: 16px;
			padding-bottom: 16px;
		}
	}
	&__logo {
		width: 117px;
		@media (max-width: $md) {
			width: 80px;
		}
	}
	&__btn {
		@media (max-width: $lg) {
			display: none;
		}
	}
	&__burger {
		display: none;
		@media (max-width: $lg) {
			display: block;
		}
	}
}

#burger {
	width: 45px;
	height: 25px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
}

#burger span {
	display: block;
	position: absolute;
	height: 4px;
	width: 100%;
	background: $green;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}

#burger span:nth-child(1) {
	top: 0px;
}

#burger span:nth-child(2),
#burger span:nth-child(3) {
	top: 12.5px;
}

#burger span:nth-child(4) {
	top: 25px;
}

#burger.open span:nth-child(1) {
	top: 12.5px;
	width: 0%;
	left: 50%;
}

#burger.open span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

#burger.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

#burger.open span:nth-child(4) {
	top: 18px;
	width: 0%;
	left: 50%;
}

.menu {
	display: flex;
	align-items: center;
	@media (max-width: $lg) {
		display: none;
	}
	&__item {
		text-decoration: none;
		color: inherit;
		font-weight: 700;
		margin-left: 60px;
		transition: color 0.3s ease-out;
		&:first-of-type {
			margin-left: 0;
		}
		&:hover {
			color: $green;
		}
		&--active {
			color: $green;
		}
	}
}

.mobile-menu {
	background-color: #fff;
	position: absolute;
	width: 100%;
	left: 0;
	z-index: 1;
	transform: translateY(-100%);
	transition: transform 0.3s ease-out;
	padding-top: 12px;
	padding-bottom: 12px;
	z-index: 10;

	&--active {
		transform: translateY(0);
	}
	&__menu {
	}
	&__item {
		padding-top: 12px;
		padding-bottom: 12px;
		border-bottom: 1px solid rgba($greytext, 0.6);
		display: block;
		text-decoration: none;
		color: inherit;
		font-weight: 700;
		&:last-of-type {
			border-bottom: 0;
		}
		&--active {
			color: $green;
		}
	}
	&__btn {
		width: fit-content;
		margin-top: 12px;
	}
}

.hero {
	background: rgb(255, 255, 255);
	height: 100vh;
	background: linear-gradient(
		0deg,
		#f2fcf67c 0%,
		rgba(90, 165, 81, 0.219) 100%
	);
	margin-bottom: 70px;
	height: 220px;
	text-align: center;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-x: cut;
	$hero: &;
	@media (max-width: $md) {
		height: 180px;
		margin-bottom: 80px;
	}
	&--big {
		min-height: 646px;
		height: auto;
		flex-direction: column;
		padding-bottom: 60px;
		padding-top: 90px;
		margin-bottom: 61px;
		#{$hero}__title {
			margin-bottom: 80px;
			max-width: 750px;
		}
		#{$hero}__text {
			max-width: 831px;
			text-align: left;
			color: #000;
			font-size: 20px;
			line-height: 34px;
			padding-left: 29px;
			border-left: 2px solid $green;

			@media (max-width: $md) {
				padding-left: 16px;
				padding-right: 16px;
				font-size: 18px;
				line-height: 30px;
				text-align: center;
				border-left: 0;
			}
		}
		#{$hero}__image {
			max-width: 1700px;
			left: 50%;
			transform: translateX(-50%);
		}
		@media (max-width: $md) {
			height: auto !important;
		}
	}
	&__textblock {
		position: absolute;
		// bottom: 0;
		// transform: translateY(50%);
		width: 100%;
		max-width: 890px;
		@media (max-width: $md) {
			position: static;
		}
	}

	&--main {
		// min-height: 646px;
		height: 100vh;
		overflow: hidden;
		flex-direction: column;
		padding-bottom: 90px;
		margin-bottom: 190px;
		padding-top: 90px;
		justify-content: center;

		@media (max-width: $md) {
			padding-bottom: 0;
			margin-bottom: 70px;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 100px;
			width: 100%;
			height: 100%;
			background-position: bottom;
			background-image: url("./img/homegraphs.png");
			background-size: cover;
			filter: grayscale(100%);
		}
		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(
				0deg,
				#ffffffbb,
				rgba(90, 165, 81, 0.219) 100%
			);
		}

		.container {
			position: relative;
			z-index: 1;
		}

		@media (max-width: $md) {
			padding-left: 16px;
			padding-right: 16px;
			font-size: 18px;
			line-height: 30px;
			text-align: center;
			border-left: 0;
		}

		#{$hero}__image {
			// max-width: 1700px;
			// left: 50%;
			// transform: translateX(-50%);
			width: 100%;
		}
		#{$hero}__title {
			margin-bottom: 40px;
		}
		#{$hero}__text {
			font-size: 25px;
			line-height: 51px;
			text-align: center;
			max-width: 940px;
			font-weight: 600;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 66px;
			strong {
				color: #04724d;
				font-weight: 900;
			}
		}
		#{$hero}__lines {
			width: 32%;
			// position: absolute;
			// right: 0;
			// height: 100%;
			// top: 0;
			// filter: grayscale(100%);
			// opacity: 0.9;
			// @media (max-width: $md) {
			// 	width: 40%;
			// }
		}
		@media (max-width: $md) {
			height: auto !important;
		}
	}

	&__title {
		font-weight: 900;
		font-size: 60px;
		line-height: 40px;
	}
	&__image {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		filter: grayscale(100%);
		opacity: 0.7;
		@media (max-width: $md) {
			width: 100%;
			// left: -50%;
		}
	}
	&__lines {
		width: 22%;
		position: absolute;
		right: 0;
		height: 100%;
		top: 0;
		filter: grayscale(100%);
		opacity: 0.9;
		@media (max-width: $md) {
			width: 40%;
		}
	}
}

.text-block-section {
	margin-top: 80px;
	margin-bottom: 80px;
	position: relative;
	z-index: 5;
	@media (max-width: $md) {
		margin-bottom: 80px;
		margin-top: 80px;
	}
}
.text-block {
	padding: 30px 35px;
	background-color: $green;
	color: #fff;
	font-size: 18px;
	border-radius: 20px;
	line-height: 189%;
	padding-right: 50px;
	border: 0;
	@media (max-width: $md) {
		padding: 25px 30px;
		padding-right: 30px;
	}
	&--lg {
		padding-top: 55px;
		padding-bottom: 55px;
		text-align: center;
	}
	&--sm {
		text-align: center;
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		text-decoration: underline;
		cursor: pointer;
		span {
			text-decoration: none;
		}
	}
	&__title {
		text-align: center;
		font-weight: 600;
		font-size: 32px;
		line-height: 44px;
		color: #fff;
		margin-bottom: 21px;
		display: block;
		text-decoration: none;
	}
	&__line {
		width: 68px;
		height: 2px;
		background-color: #fff;
		margin-bottom: 21px;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
	&__bold {
		font-weight: 700;
		font-size: 18px;
		margin-top: 30px;
		display: block;
	}
}

.members {
	background-color: $silvergrey;
	padding-top: 60px;
	padding-bottom: 100px;
	$members: &;
	@media (max-width: $md) {
		padding-bottom: 80px;
	}
	&__number {
		color: #fff;
		font-weight: 600;
		font-size: 108px;
		line-height: 147px;
		position: absolute;
		top: -75px;
		left: -35px;
		z-index: 0;
		@media (max-width: $md) {
			font-size: 80px;
			left: -10px;
		}
	}
	&--v2 {
		padding-top: 100px;
		padding-bottom: 68px;
		#{$members}__column {
			@media (max-width: $md) {
				margin-bottom: 25px;
			}
		}
	}
	&__container {
		max-width: 1168px;
		padding-left: 16px;
		padding-right: 16px;
		margin-left: auto;
		margin-right: auto;
	}
	&__title {
		margin-bottom: 60px;
		font-weight: 900;
		font-size: 32px;
		color: $green;
		text-align: center;
		margin-bottom: 60px;
	}
	&__wrap {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 15px;
		row-gap: 50px;
		@media (max-width: $lg) {
			// flex-direction: column;
			// align-items: center;
			justify-content: center;
			grid-template-columns: 1fr;
		}
	}
	&__logo {
		height: 50px;
		max-width: 250px;
		object-fit: contain;
		margin-bottom: 30px;
		@media (max-width: $lg) {
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}
	&__line {
		height: 2px;
		width: 68px;
		margin-bottom: 21px;
		background-color: $green;
		position: relative;
		z-index: 1;
		@media (max-width: $lg) {
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}
	&__heading {
		font-size: 25px;
		line-height: 34px;
		font-weight: 600;
		margin-bottom: 24px;
		position: relative;
		z-index: 1;
	}
	&__text {
		color: $gray;
		font-size: 18px;
		font-weight: 400;
		line-height: 34px;
		margin-bottom: 27px;
		min-height: 102px;
	}
	&__column {
		max-width: 336px;
		position: relative;
		@media (max-width: $lg) {
			text-align: center;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&__link {
		color: $green;
		text-decoration: none;
		font-size: 14px;
		font-weight: 600;
		svg {
			width: 13px;
			margin-left: 21px;
		}
	}
}

.footer {
	color: $greytext;
	background-color: $darkbg;
	width: 100vw;
	overflow: hidden;
	&__container {
		max-width: 1352px;
		padding-left: 16px;
		padding-right: 16px;
		margin-left: auto;
		margin-right: auto;
		display: grid;
		grid-template-columns: 4fr 8fr;
		@media (max-width: $lg) {
			grid-template-columns: 1fr;
		}
	}
	&__logo-wrap {
		display: inline-block;
		cursor: pointer;
	}
	&__left {
		background-color: $semidarkbg;
		position: relative;
		padding: 60px 0;
		border-right: 1px solid rgba($greytext, 0.2);
		@media (max-width: $lg) {
			border-right: 0;
		}

		& > div {
			position: relative;
			z-index: 1;
		}
		&::before {
			content: "";
			background-color: $semidarkbg;
			position: absolute;
			top: 0;
			height: 100%;
			width: 120vw;
			right: 0;
			@media (max-width: $lg) {
				right: -20px;
			}
		}
	}
	&__logo {
		width: 117px;
		margin-bottom: 55px;
	}
	&__right {
		padding: 60px 0;
		padding-left: 68px;
		@media (max-width: $lg) {
			padding-left: 0;
		}
	}
	&__right-grid {
		display: flex;
		justify-content: space-between;
		@media (max-width: $md) {
			display: block;
		}
	}
	&__contact {
		display: flex;
		align-items: center;
		color: $greytext;
		text-decoration: none;
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 35px;
		line-height: 20px;
		svg {
			max-width: 25px;
			max-height: 29px;
			margin-right: 15px;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	&__btn {
		margin-top: 60px;
		width: fit-content;
	}
	&__menu {
		@media (max-width: $md) {
			display: flex;
			flex-wrap: wrap;
			column-gap: 20px;
			row-gap: 20px;
		}
	}
	&__menu-item {
		font-weight: 700;
		color: $greytext;
		margin-bottom: 21px;
		display: block;
		text-decoration: none;
		line-height: 19px;

		&--nested {
			padding-left: 20px;
			font-size: 14px;
			@media (max-width: $md) {
				padding-left: 0;
			}
		}
		@media (max-width: $md) {
			margin-bottom: 0;
		}
	}
	&__socials {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 60px;
		@media (max-width: $md) {
			justify-content: flex-start;
			margin-bottom: 20px;
			margin-top: 40px;
		}
	}

	&__socialswrap {
		display: flex;
		align-items: flex-end;
	}

	&__social {
		width: 44px;
		height: 44px;
		margin-left: 30px;
		&:first-of-type {
			margin-left: 0;
		}
	}
	&__text {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: $greytext;
		max-width: 347px;
		display: block;
		margin-left: auto;
		text-align: right;
		@media (max-width: $md) {
			font-size: 12px;
			text-align: start;
			margin-left: 0;
			max-width: none;
		}
	}
	&__copyright {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		margin-top: 40px;
	}
}

.faq-qwrap {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 50px;
}

.faqs {
	margin-top: 109px;
	margin-bottom: 90px;
	&__title {
		margin-bottom: 60px;
		font-weight: 900;
		font-size: 32px;
		color: $green;
		margin-bottom: 60px;

		&--mt {
			margin-top: 90px;
		}
	}

	&__question {
		font-family: "Inter";
		font-weight: 700;
		color: #000;
		margin-top: 60px;
		margin-bottom: 24px;
		font-size: 25px;
		line-height: 34px;
	}
	&__line {
		height: 2px;
		background-color: $green;
		width: 76px;
		margin-bottom: 21px;
		margin-top: 24.5px;
	}
	&__answer {
		color: $gray;
		font-size: 18px;
		line-height: 189%;
	}
}

.list {
	margin-top: 61px;
	margin-bottom: 60px;
	padding-left: 20px;
	&__list {
		list-style: decimal;
	}
	&__bullet {
		color: $gray;
		font-size: 18px;
		line-height: 189%;
		font-weight: 600;
		// padding-left: 20px;
		position: relative;
		cursor: pointer;
		// text-decoration: underline;
		// &::before {
		// 	content: "";
		// 	width: 7px;
		// 	height: 7px;
		// 	border-radius: 50%;
		// 	background-color: $green;
		// 	position: absolute;
		// 	left: 0;
		// 	top: 14px;
		// }
		&::marker {
			color: $green;
			font-weight: 600;
			font-size: 18px;
			line-height: 189%;
		}
	}
}

.pledge {
	margin-bottom: 60px;
	margin-top: 60px;
	padding-top: 70px;
	&--last {
		margin-bottom: 90px;
	}

	&__title {
		font-weight: 600;
		font-size: 25px;
		line-height: 34px;
		color: #000000;
		position: relative;
	}
	&__line {
		margin-top: 24px;
		margin-bottom: 21px;
		width: 175px;
		height: 2px;
		background-color: $green;
	}
	&__text {
		font-size: 18px;
		line-height: 189%;
		color: $gray;
	}
	&__number {
		font-weight: 600;
		font-size: 108px;
		line-height: 147px;
		color: #e6e6e6;
		position: absolute;
		z-index: -1;
		top: -65px;
		left: -30px;
	}
	&__list {
		color: $gray;
		font-size: 18px;
		line-height: 189%;
		padding-left: 20px;
		position: relative;
		margin-bottom: 2px;
		&::before {
			content: "";
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background-color: $green;
			position: absolute;
			left: 0;
			top: 14px;
		}
	}
}

.report-title {
	font-weight: 900;
	font-size: 24px;
	line-height: 26px;
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	margin-bottom: 40px;
}

.report {
	background-color: $lightbg;
	padding-top: 60px;
	padding-bottom: 60px;
	&--v2 {
		background-color: #f2f2f2;
	}
	&--report {
		background-color: #f2f2f2;
	}
	&__title {
		font-weight: 600;
		font-size: 25px;
		line-height: 34px;
		text-align: center;
	}
	&__line {
		width: 300px;
		height: 2px;
		background-color: #616161;
		margin-left: auto;
		margin-right: auto;
		margin-top: 24px;
		margin-bottom: 21px;
	}
	&__text {
		font-weight: 400;
		font-size: 18px;
		line-height: 34px;
		color: #616161;
		&--center {
			text-align: center;
		}
	}
	&__svg {
		margin-left: auto;
		margin-right: auto;
		display: block;
		margin-bottom: 30px;
	}
}

.list-item {
	color: $gray;
	font-size: 18px;
	line-height: 189%;
	padding-left: 20px;
	position: relative;
	margin-bottom: 2px;
	&::before {
		content: "";
		width: 7px;
		height: 7px;
		border-radius: 50%;
		background-color: $green;
		position: absolute;
		left: 0;
		top: 14px;
	}
}

.text {
	padding-top: 90px;
	padding-bottom: 90px;
	&__title {
		font-weight: 600;
		font-size: 25px;
		line-height: 34px;
		color: #000000;
		margin-bottom: 24px;
	}
	&__line {
		width: 68px;
		height: 2px;
		background-color: $green;
		margin-bottom: 21px;
		margin-top: 24px;
	}
	&__text {
		font-weight: 400;
		font-size: 18px;
		line-height: 34px;
		color: #616161;
	}
}

.emblem {
	margin-top: 70px;
	margin-bottom: 40px;
	&__title {
		font-weight: 900;
		font-size: 33px;
		line-height: 40px;
		color: #616161;
		text-align: center;
		margin-bottom: 20px;
	}
	&__image {
		max-width: 298px;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
}

.greentitle {
	color: $green;
	font-weight: 900;
	font-size: 32px;
	line-height: 39px;
	margin-bottom: 60px;
	text-align: center;
	&--spacing {
		margin-top: 100px;
		margin-bottom: 60px;
		@media (max-width: $md) {
			margin-top: 80px;
			margin-bottom: 60px;
		}
	}
}

.subtitle {
	color: $gray;
	font-weight: 400;
	font-size: 18px;
	line-height: 34px;
	text-align: center;
}

.director {
	margin-top: 80px;
	margin-bottom: 80px;

	$director: &;

	&--last {
		margin-bottom: 90px;
	}

	&--reversed {
		#{$director}__flex {
			flex-direction: row-reverse;
		}
		#{$director}__quote {
			left: 0;
		}
		#{$director}__box {
			display: flex;
			justify-content: flex-end;
			@media screen and (max-width: $lg) {
				display: block;
			}
		}
	}
	&__container {
		max-width: 1400px;
		padding-left: 16px;
		padding-right: 16px;
		margin-left: auto;
		margin-right: auto;
	}
	&__flex {
		display: flex;
		align-items: flex-start;
		position: relative;
		@media screen and (max-width: $lg) {
			display: block;
		}
	}
	&__content {
		max-width: 680px;
		width: 100%;

		@media screen and (max-width: 1370px) {
			max-width: 600px;
		}
		@media screen and (max-width: 1270px) {
			max-width: 500px;
		}
		// @media screen and (max-width: 1270px) {
		// 	max-width: 460px;
		// }
		@media screen and (max-width: $lg) {
			max-width: none;
		}
	}
	&__title {
		font-weight: 600;
		font-size: 25px;
		line-height: 34px;
		margin-bottom: 24px;
		@media screen and (max-width: $lg) {
			text-align: center;
		}
	}
	&__line {
		width: 68px;
		height: 2px;
		background-color: $green;
		margin-bottom: 21px;
		@media screen and (max-width: $lg) {
			margin-right: auto;
			margin-left: auto;
		}
	}
	&__text {
		color: $gray;
		font-weight: 400;
		font-size: 18px;
		line-height: 34px;
		@media screen and (max-width: $lg) {
			text-align: center;
			margin-bottom: 20px;
		}
	}
	&__image {
		width: 370px;
		height: 420px;
		object-fit: cover;
		margin-left: 50px;
		margin-right: 50px;
		@media screen and (max-width: $lg) {
			width: 100%;
			max-width: 600px;
			height: auto;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}
	&__quote {
		background-color: $green;
		padding: 30px;
		border-radius: 20px;
		color: #fff;
		width: 347px;
		position: absolute;
		right: 0;
		top: 30px;
		@media screen and (max-width: $lg) {
			position: static;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
		}
	}
	&__quote-icon {
		width: 33px;
		margin-bottom: 13px;
	}
	&__quote-text {
		font-weight: 600;
		font-size: 18px;
		line-height: 28px;
		padding-right: 9px;
	}
	&__quote-name {
		font-weight: 400;
		font-size: 16px;
		line-height: 33px;
		text-align: right;
	}
	&__box {
		position: relative;
		width: 100%;
		&--spacing {
			padding-top: 85px;
		}
	}
}

.title-text {
	margin-top: 60px;
	margin-bottom: 60px;
}

.form {
	padding-top: 61px;
	padding-bottom: 61px;
	background-color: $formbg;
}
.form-block {
	background-color: #fff;
	border-radius: 20px;
	padding: 60px;
	&__input {
		border: 0;
		border-bottom: 1px solid $green;
		color: $gray;
		font-weight: 600;
		font-size: 18px;
		line-height: 34px;
		padding: 0;
		width: 100%;
		font-family: "Inter", sans-serif;
		&:focus {
			outline: none;
		}
		@media (max-width: $md) {
			margin-bottom: 20px;
		}
		&--half {
			max-width: calc(50% - 29px);
			margin-bottom: 24px;
			@media (max-width: $md) {
				max-width: 100%;
			}
		}
		&--area {
			margin-bottom: 16px;
			&::placeholder {
				font-family: "Inter", sans-serif;
			}
		}
	}
	&__label {
		font-size: 16px;
		color: $gray;
		font-weight: 400;
		margin-bottom: 8px;
	}
	&__message {
		font-size: 14px;
		margin-bottom: 24px;
	}
	&__row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 58px;
		margin-bottom: 30px;
		@media (max-width: $md) {
			display: block;
			margin-bottom: 0;
		}
	}
	&__btn {
		cursor: pointer;
		background-color: $green;
		color: #fff;
		text-transform: uppercase;
		padding: 9px 72px;
		border-radius: 20px;
		border: 0;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
		margin-top: 40px;
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		color: #fffff2;
		@media (max-width: $md) {
			margin-top: 20px;
		}
	}
	&__success {
		color: green;
		display: block;
		text-align: center;
		margin-top: 16px;
		line-height: 1.2;
	}
	&__failed {
		color: red;
		display: block;
		text-align: center;
		margin-top: 16px;
	}
	&__captcha {
		display: inline-block;
	}
	&__captcha-row {
		display: flex;
		justify-content: center;
	}
}

.mt-0 {
	margin-top: 0;
}
.mb-0 {
	margin-bottom: 0;
}

.mb-1 {
	margin-bottom: 8px;
}

.center {
	text-align: center;
}

.cursor {
	cursor: pointer;
}

.underline {
	text-decoration: underline;
}
.relative {
	position: relative;
}

.bullets {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 32px;
	padding-right: 24px;
	margin-top: 60px;
	margin-bottom: 60px;
	&__list {
		list-style: initial;
		li {
			color: #616161;
			font-size: 18px;
			font-weight: 600;
			line-height: 34px;
			margin-bottom: 10px;
		}
	}
}

.d-block {
	display: block;
}

.loader {
	margin-right: 8px;
	font-size: 10px;
	text-indent: -9999em;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: #ffffff;
	overflow: hidden;
	background: -moz-linear-gradient(
		left,
		#ffffff 10%,
		rgba(255, 255, 255, 0) 42%
	);
	background: -webkit-linear-gradient(
		left,
		#ffffff 10%,
		rgba(255, 255, 255, 0) 42%
	);
	background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
	background: -ms-linear-gradient(
		left,
		#ffffff 10%,
		rgba(255, 255, 255, 0) 42%
	);
	background: linear-gradient(
		to right,
		#ffffff 10%,
		rgba(255, 255, 255, 0) 42%
	);
	position: relative;
	-webkit-animation: load3 1.4s infinite linear;
	animation: load3 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
.loader:before {
	width: 50%;
	height: 50%;
	background: #ffffff;
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
}
.loader:after {
	background: #04724d;
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: "";
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
@-webkit-keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.coming-logo {
	width: 200px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 48px;
}
.construction-logo {
	width: 100%;
	max-width: 400px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 32px;
	z-index: 2;
}
